<template>
  <div>
    <form class="search" @submit.prevent="search">
      <input
        placeholder="搜索商品名称"
        type="text"
        v-model="name"
        autocomplete="off"
        @keyup.enter="searchList"
      />
      <div class="img">
        <img
          src="../../../static/image/sousuo.png"
          alt=""
          @click="searchList"
        />
      </div>
      <div class="close" @click="back">取消</div>
    </form>
    <div>
      <div class="title history" v-if="history.length">
        <span>搜索历史</span>
        <div>
          <img @click="del" src="../../assets/images/del.png" alt="" />
        </div>
      </div>
      <div class="content">
        <span @click="goTo(item)" v-for="item in history" :key="item">{{
          item
        }}</span>
      </div>
      <div class="title">热门搜索</div>
      <div class="content">
        <span v-for="item in hot" @click="goTo(item.word)" :key="item.id">{{
          item.word
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: '',
      params: {
        pageindex: 1,
        pagesize: 20,
      },
      getMarketSearchResult: [],
      isSearch: false,
      hot: [],
      history: [],
    };
  },
  mounted() {
    this.isSearch = false;
    this.getHistory();
    this.shopHotWordList();
  },
  methods: {
    del() {
      localStorage.removeItem('historyKey');
      this.getHistory();
    },
    goTo(item) {
      const query = item.replaceAll('#', '**');
      this.$router.push(`/searchResult?key=${encodeURI(query)}`);
    },
    // 获取历史
    getHistory() {
      const historyKey = localStorage.getItem('historyKey');
      if (historyKey) {
        this.history = historyKey.split(',');
      } else {
        this.history = [];
      }
    },
    async shopHotWordList() {
      try {
        const res = await this.$api.shopHotWordList();
        if (res.code === 0) {
          this.hot = res.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    back() {
      this.$router.back();
    },
    bazaar(item) {
      console.log(item);
      this.$router.push({
        path: '/bazaar',
        query: {
          id: item.goodsId,
          entrustId: item.id,
        },
      });
      this.getGoodmarketlist();
    },
    search() {
      return false;
    },
    searchList() {
      if (!this.name) return this.$toast('请输入搜索内容');
      // this.params.pageindex = 1;
      // this.params.pagesize = 20;
      // this.getGoodmarketlist();
      try {
        const historyKey = localStorage.getItem('historyKey');
        if (historyKey) {
          const hotList = historyKey.split(',');
          hotList.unshift(this.name);
          const list = new Set(hotList);
          localStorage.setItem('historyKey', Array.from(list).join(','));
        } else {
          localStorage.setItem('historyKey', this.name);
        }
      } catch (error) {
        console.log(error);
      }
      const query = this.name.replaceAll('#', '**');
      this.$router.push(`/searchResult?key=${encodeURI(query)}`);
    },
    getGoodmarketlist() {
      let params = Object.assign(
        {
          name: this.name,
          type: this.add,
        },
        this.params,
      );
      this.$api.getMarketSearchResult(params).then((res) => {
        if (res.code == 0) {
          this.getMarketSearchResult = res.data;
          this.getHistory();
        }
        this.isSearch = true;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  padding: 0 20px;
  span {
    font-size: 12px;
    color: #666666;
    padding: 5px 13px;
    background: #f8f8f8;
    border-radius: 20px;
    margin: 5px 15px 5px 0;
    vertical-align: middle;
    white-space: nowrap;
    display: inline-block;
  }
}
.title {
  font-size: 16px;
  font-weight: 600;
  padding: 0 20px;
  margin: 10px 0;
  span {
    font-size: 16px;
    font-weight: 600;
  }
  &.history {
    display: flex;
    justify-content: space-between;
    img {
      width: 15px;
    }
  }
}
.def {
  text-align: center;
  p {
    font-size: 14px;
    color: #999;
  }
  img {
    width: 35%;
    margin-right: -25px;
  }
}
.search {
  display: flex;
  height: 65px;
  align-items: center;
  position: relative;
  justify-content: center;
}
.search .img {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 20px;
  position: absolute;
  right: 40px;
  img {
    width: 100%;
    vertical-align: middle;
  }
}
.close {
  font-size: 14px;
  color: #aaa;
  margin-left: 10px;
  text-align: right;
  margin-right: 10px;
}
.search input {
  flex: 1;
  height: 33px;
  background-color: #f3f4f8;
  font-size: 12px;
  border: 0;
  border-radius: 15px;
  padding-left: 16px;
  margin-left: 12px;
}
.box {
  background-color: #f1f1f1;
  overflow: hidden;
  padding-top: 20px;
}
.anyTypelist_box {
  display: flex;
}
.anyTypelist {
  color: #999999;
  font-size: 16px;
  margin-left: 10px;
  line-height: 50px;
}

.anyTypelist_tow {
  font-size: 19px;
  margin-left: 20px;
  font-weight: 600;
  line-height: 50px;
}

.shop_img {
  background-color: #ffffff;
  margin-left: 17px;
  width: 163px;
  float: left;
  margin-bottom: 14px;
  border-radius: 5px;
  box-shadow: 2px 2px 2px 2px rgba(212, 210, 210, 0.192);
  div {
    margin-left: 8px;
  }
  img {
    display: inline-block;
    width: 100%;
    height: 190px;
    border-radius: 5px;
  }
  .shop_text {
    font-size: 13px;
  }
  .info {
    font-size: 10px;
    color: #aaa;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90%;
  }
  .shop_explain {
    font-size: 8px;
    color: #999999;
  }
  .shop_mongy {
    font-size: 13px;
    padding-bottom: 15px;
    .shop_sem {
      font-size: 8px;
    }
  }
}
</style>
